import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/store';
import { LoginRequest, LoginResponse } from 'features/auth/data';
import { UploadRequest, UploadResponse } from 'features/xray/data';

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: (headers, { getState }) => {
            const { token } = (getState() as RootState).auth;
            if (token) {
                headers.set('authorization', token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        logIn: builder.mutation<LoginResponse, LoginRequest>({
            query: ({ username, password }: LoginRequest) => {
                const data = new FormData();
                data.append('login', username);
                data.append('password', password);
                return {
                    url: `/api/login`,
                    method: 'POST',
                    body: data,
                };
            },
            transformResponse: (responseData, requestData, arg) => {
                return {
                    token: (responseData as any).hash,
                };
            },
        }),
        uploadFile: builder.mutation<UploadResponse, UploadRequest>({
            query: ({ file }: UploadRequest) => {
                const data = new FormData();
                data.append('file', file);
                return {
                    url: `/api/process`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        saveTable: builder.mutation({
            query: ({ fileName, json }: { fileName: string; json: string }) => {
                const data = new FormData();
                data.append('jsonFileName', fileName);
                data.append('json', json);
                return {
                    url: `/api/table`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const { useLogInMutation, useUploadFileMutation, useSaveTableMutation } = appApi;
