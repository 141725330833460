import { RootState } from 'app/store';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { setTable } from '../../../slice';

const EditableTd = ({ name = [] }: { name: string[] }) => {
    const dispatch = useDispatch();
    const xray = useSelector((state: RootState) => state.xray);

    const tdValue = get(xray.table, name);
    const initTableValue = xray.resultProcess ? get(xray.resultProcess.table, name) : undefined;

    return (
        <td align="center">
            <span>{tdValue}</span>
            <input
                className={initTableValue === tdValue ? '' : 'changed'}
                type="number"
                name={name.join('/')}
                value={tdValue ?? ''}
                onChange={(event) => {
                    const newValue = Number(event.target.value);
                    dispatch(setTable({ name, newValue }));
                }}
            />
        </td>
    );
};

export { EditableTd };
