import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { sum } from 'lodash';

const SumTd = ({ name, sources = [] }: { name: string[], sources: string[][] }) => {
    const xray = useSelector((state: RootState) => state.xray);
    const tdValue = sum(sources.map(source => get(xray.table, source)))
    const initTableValue = xray.resultProcess ? get(xray.resultProcess.table, name) : undefined;

    return (
        <td align="center">
            <span className={initTableValue === tdValue ? '' : 'changed'} style={{ display: 'block' }}>
                {tdValue}
            </span>
            <input hidden={true} type="number" name={name.join('/')} value={tdValue} />
        </td>
    );
};

export { SumTd };