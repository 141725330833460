import { useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useSaveTableMutation } from 'shared/api';
import { ToolsBar } from './ToolsBar';
import { TableHead } from './TableHead';
import { EditableTd } from './EditableTd';
import { SumTd } from './SumTd';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import './index.css';
import set from 'lodash/set';

const Table = () => {
    const { resultProcess } = useSelector((state: RootState) => state.xray);
    const formRef = useRef(null);

    const [, setIsDebounced] = useState(false);
    const [saveTable] = useSaveTableMutation();

    const debouncedChangeHandler = useMemo(
        () =>
            debounce(() => {
                setIsDebounced(false);
                if (resultProcess?.fileLink) {
                    if (formRef.current) {
                        const formData = new FormData(formRef.current);
                        const dataObject = Object.fromEntries(formData.entries());
                        const iterableData = Object.entries(dataObject);
                        let newFormData = {}

                        iterableData.forEach(([key, value]) => {
                            set(newFormData, key.split('/'), value)
                        })

                        saveTable({ fileName: resultProcess.jsonFileName, json: JSON.stringify(newFormData) });
                    }
                }
            }, 2000),
        [resultProcess, saveTable]
    );

    const handleChange = () => {
        if (!resultProcess) return;
        debouncedChangeHandler();
        setIsDebounced(true);
    };

    return (
        <div>
            <ToolsBar />
            <form ref={formRef} onChange={handleChange}>
                <table  className="iksweb">
                    <TableHead />
                    <tbody>
                        <tr>
                            <td align="center">1-й МФ</td>
                            <EditableTd name={['Без имени', '1-й МФ', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Без имени', '1-й МФ', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Без имени', '1-й МФ', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Без имени', '1-й МФ', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Дистальные межфаланговые (ДМФ) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">2</td>
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                                sources={[
                                    ['Без имени', '1-й МФ', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                                sources={[
                                    ['Без имени', '1-й МФ', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Сужение щелей'],
                                ]}
                            />
                            <SumTd
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                                sources={[
                                    ['Без имени', '1-й МФ', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Дистальные межфаланговые (ДМФ) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                                sources={[
                                    ['Без имени', '1-й МФ', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Сужение щелей'],
                                ]}
                            />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Проксимальные межфаланговые (ПМФ) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">2</td>
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                name={['Проксимальные межфаланговые (ПМФ) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                                sources={[
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Проксимальные межфаланговые (ПМФ) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                                sources={[
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Сужение щелей'],
                                ]}
                            />
                            <SumTd
                                name={['Проксимальные межфаланговые (ПМФ) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                                sources={[
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Проксимальные межфаланговые (ПМФ) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                                sources={[
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Сужение щелей'],
                                ]}
                            />
                        </tr>

                        <tr>
                            <td align="center" colSpan={5}>
                                Пястно-фаланговые (ПЯФ) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">1</td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">2</td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">1-й ЗП сустав</td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Справа', 'Эрозии']} />
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd name={['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Слева', 'Эрозии']} />
                            <td className="td-x" align="center">
                                X
                            </td>
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                                sources={[
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Справа', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                                sources={[
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Сужение щелей'],
                                ]}
                            />
                            <SumTd
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                                sources={[
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Слева', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Пястно-фаланговые (ПЯФ) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                                sources={[
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Сужение щелей'],
                                ]}
                            />
                        </tr>
                        <tr>
                            <td align="center" colSpan={5}>
                                Запястно-пястные (ЗП) суставы
                            </td>
                        </tr>
                        <tr>
                            <td align="center">3</td>
                            <td className="td-x" align="center" rowSpan={3}>
                                X
                            </td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', '3.0', 'Справа', 'Сужение щелей']} />
                            <td className="td-x" align="center" rowSpan={3}>
                                X
                            </td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', '3.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">4</td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', '4.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', '4.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">5</td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', '5.0', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', '5.0', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">Запястье</td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Эрозии']} />
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Сужение щелей']} />
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Эрозии']} />
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr>
                            <td align="center">Локтевая кость</td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Справа', 'Эрозии']} />
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Слева', 'Эрозии']} />
                            <td className="td-x" align="center">
                                X
                            </td>
                        </tr>
                        <tr>
                            <td align="center">ЛЗ</td>
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Справа', 'Сужение щелей']} />
                            <td className="td-x" align="center">
                                X
                            </td>
                            <EditableTd name={['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Слева', 'Сужение щелей']} />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Суммарно</td>
                            <SumTd
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Справа', 'Эрозии']}
                                sources={[
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Эрозии'],
                                    ['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Справа', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Справа', 'Сужение щелей']}
                                sources={[
                                    ['Запястно-пястные (ЗП) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '5.0', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Справа', 'Сужение щелей'],
                                ]}
                            />
                            <SumTd
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Слева', 'Эрозии']}
                                sources={[
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Эрозии'],
                                    ['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Слева', 'Эрозии'],
                                ]}
                            />
                            <SumTd
                                name={['Запястно-пястные (ЗП) суставы', 'Суммарно', 'Слева', 'Сужение щелей']}
                                sources={[
                                    ['Запястно-пястные (ЗП) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '5.0', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Слева', 'Сужение щелей'],
                                ]}
                            />
                        </tr>
                        <tr style={{ backgroundColor: 'rgb(20 214 188 / 8%)' }}>
                            <td align="center">Общая сумма</td>

                            {/* Общая сумма - Справа - Эрозии */}
                            <SumTd
                                name={['Общая сумма', 'Справа', 'Эрозии']}
                                sources={[
                                    // Без имени
                                    ['Без имени', '1-й МФ', 'Справа', 'Эрозии'],

                                    // Дистальные межфаланговые (ДМФ) суставы
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Эрозии'],

                                    // Проксимальные межфаланговые (ПМФ) суставы
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии'],

                                    // Запястно-пястные (ЗП) суставы
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Эрозии'],
                                    ['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Справа', 'Эрозии'],

                                    // Пястно-фаланговые (ПЯФ) суставы
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Эрозии'],
                                ]}
                            />

                            {/* Общая сумма - Справа - Сужение щелей */}
                            <SumTd
                                name={['Общая сумма', 'Справа', 'Сужение щелей']}
                                sources={[
                                    // Без имени
                                    ['Без имени', '1-й МФ', 'Справа', 'Сужение щелей'],

                                    // Дистальные межфаланговые (ДМФ) суставы
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Сужение щелей'],

                                    // Проксимальные межфаланговые (ПМФ) суставы
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Справа', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Сужение щелей'],

                                    // Запястно-пястные (ЗП) суставы
                                    ['Запястно-пястные (ЗП) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '5.0', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Справа', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Справа', 'Сужение щелей'],

                                    // Пястно-фаланговые (ПЯФ) суставы
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Справа', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Справа', 'Сужение щелей'],
                                ]}
                            />

                            {/* Общая сумма - Слева - Эрозии */}
                            <SumTd
                                name={['Общая сумма', 'Слева', 'Эрозии']}
                                sources={[
                                    // Без имени
                                    ['Без имени', '1-й МФ', 'Слева', 'Эрозии'],

                                    // Дистальные межфаланговые (ДМФ) суставы
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Эрозии'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Справа', 'Эрозии'],

                                    // Проксимальные межфаланговые (ПМФ) суставы
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Эрозии'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Справа', 'Эрозии'],

                                    // Запястно-пястные (ЗП) суставы
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Эрозии'],
                                    ['Запястно-пястные (ЗП) суставы', 'Локтевая кость', 'Слева', 'Эрозии'],

                                    // Пястно-фаланговые (ПЯФ) суставы
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Эрозии'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Эрозии'],
                                ]}
                            />

                            {/* Общая сумма - Слева - Сужение щелей */}
                            <SumTd
                                name={['Общая сумма', 'Слева', 'Сужение щелей']}
                                sources={[
                                    // Без имени
                                    ['Без имени', '1-й МФ', 'Слева', 'Сужение щелей'],

                                    // Дистальные межфаланговые (ДМФ) суставы
                                    ['Дистальные межфаланговые (ДМФ) суставы', '2.0', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Дистальные межфаланговые (ДМФ) суставы', '5.0', 'Слева', 'Сужение щелей'],

                                    // Проксимальные межфаланговые (ПМФ) суставы
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '2.0', 'Слева', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Проксимальные межфаланговые (ПМФ) суставы', '5.0', 'Слева', 'Сужение щелей'],

                                    // Запястно-пястные (ЗП) суставы
                                    ['Запястно-пястные (ЗП) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', '5.0', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'Запястье', 'Слева', 'Сужение щелей'],
                                    ['Запястно-пястные (ЗП) суставы', 'ЛЗ', 'Слева', 'Сужение щелей'],

                                    // Пястно-фаланговые (ПЯФ) суставы
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1-й ЗП сустав', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '1.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '2.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '3.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '4.0', 'Слева', 'Сужение щелей'],
                                    ['Пястно-фаланговые (ПЯФ) суставы', '5.0', 'Слева', 'Сужение щелей'],
                                ]}
                            />
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
};

export { Table };
