export const download = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

export const onDownload = async (url: string, token: string | null, fileName: string) => {
    const response = await fetch(url, {
        headers: {
            authorization: token ?? '',
        },
    });
    const blob = await response.blob();
    download(blob, fileName);
};
