import classNames from 'classnames';
import '../index.css';

const IconImage = () => {
    return (
        <div className={classNames('UI-Icon')}>
            <svg
                className={classNames('UI-Icon__svg')}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M14.25 2.25H3.75a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V3.75a1.5 1.5 0 0 0-1.5-1.5Z"
                />
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M6.375 7.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM15.75 11.25 12 7.5l-8.25 8.25"
                />
            </svg>
        </div>
    );
};

export { IconImage };
