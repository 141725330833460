import { FC, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import './index.css';

type ButtonProps = {
    type?: 'primary' | 'ghost' | 'link';
    size?: 'default' | 'small';
    href?: string;
    handler?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
    popover?: string;
    download?: boolean;
};

const Button: FC<ButtonProps> = ({ type = 'primary', size, href, handler, popover, download = false, children }) => {
    const buttonClass = classNames('UI-Button', {
        'UI-Button--primary': type === 'primary',
        'UI-Button--link': type === 'link',
        'UI-Button--ghost': type === 'ghost',
        'UI-Button--small': size === 'small',
    });

    const content = (
        <>
            {children}
            {popover && <div className="UI-Button__popover">{popover}</div>}
        </>
    );

    return href ? (
        <a href={href} className={buttonClass} target="_blank" download={download} rel="noopener noreferrer">
            {content}
        </a>
    ) : (
        <button className={buttonClass} onClick={handler}>
            {content}
        </button>
    );
};

export { Button };
