import React, { ForwardedRef } from 'react';
import './index.css';

type TextInputProps = {
    name: string;
    label: string;
    type?: 'text' | 'password';
};

const TextInput = React.forwardRef(
    ({ name, label, type = 'text' }: TextInputProps, ref: ForwardedRef<HTMLInputElement | null>) => (
        <div className="UI-TextInput">
            <label className="UI-TextInput__label">
                <span className="UI-TextInput__field-name">{label}</span>
                <input ref={ref} name={name} type={type} className="UI-TextInput__input" />
            </label>
        </div>
    )
);

export { TextInput };
