import classNames from 'classnames';
import '../index.css';

const IconArchive = () => {
    return (
        <div className={classNames('UI-Icon')}>
            <svg
                className={classNames('UI-Icon__svg')}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
            >
                <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#a)">
                    <path d="M16 5.75v9.75H2.5V5.75M17.5 2H1v3.75h16.5V2ZM7.75 8.75h3" />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h18v18H0z" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export { IconArchive };
