import { useSelector } from 'react-redux';
import './index.css';
import { RootState } from 'app/store';
import { Uploader } from './components/Uploader';
import { Result } from './components/Result';
import { useState } from 'react';

const Xray = () => {
    const [selectedFile, selectFile] = useState<File | null>(null);
    const { resultProcess } = useSelector((state: RootState) => state.xray);

    return (
        <div className="F-Xray">
            <h1 className="F-Xray__title">Только для исследовательских целей</h1>
            {!resultProcess && (
                <div className="container">
                    <Uploader selectedFile={selectedFile} selectFile={selectFile} />
                </div>
            )}
            {resultProcess && <Result />}
        </div>
    );
};

export { Xray };
