import classNames from 'classnames';
import '../index.css';

const IconLogout = () => {
    return (
        <div className={classNames('UI-Icon')}>
            <svg
                className={classNames('UI-Icon__svg')}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M6.5 15.5h-3A1.5 1.5 0 0 1 2 14V3.5A1.5 1.5 0 0 1 3.5 2h3M11.75 12.5l3.75-3.75L11.75 5M15.5 8.75h-9"
                />
            </svg>
        </div>
    );
};

export { IconLogout };
