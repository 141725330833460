import { createSlice } from '@reduxjs/toolkit';
import { appApi } from 'shared/api';
import { AuthState } from './data';

const initialState: AuthState = {
    token: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut: (state) => {
            state.token = null;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(appApi.endpoints.logIn.matchFulfilled, (state, { payload }) => {
            state.token = payload.token;
        });
    },
});

export const { logOut } = authSlice.actions;
export default authSlice.reducer;
