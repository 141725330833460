import { FormEvent, useRef } from 'react';
import './index.css';
import { useLogInMutation } from 'shared/api';
import { TextInput } from 'shared/ui/TextInput';
import { Button } from 'shared/ui/Button';

const Auth = () => {
    const [logIn, { isError }] = useLogInMutation();
    const inputUserNameEl = useRef<HTMLInputElement>(null);
    const inputPasswordEl = useRef<HTMLInputElement>(null);

    const handleFormSubmit = (formEvent: FormEvent) => {
        formEvent.preventDefault();
        const username = (inputUserNameEl.current && inputUserNameEl.current.value) as string;
        const password = (inputPasswordEl.current && inputPasswordEl.current.value) as string;
        logIn({
            username,
            password,
        });
    };

    if (isError) {
        alert('Неверный логин или пароль');
    }

    return (
        <form className="F-Auth" onSubmit={handleFormSubmit}>
            <TextInput ref={inputUserNameEl} name="username" label="Логин" />
            <TextInput ref={inputPasswordEl} type="password" name="password" label="Пароль" />
            <br />
            <div className="F-Auth__footer-form">
                <Button size="small">Войти</Button>
            </div>
        </form>
    );
};

export { Auth };
