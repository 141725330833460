import { RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import 'normalize.css';
import './index.css';
import logo from '../shared/assets/images/airi-full-logo.svg';
import logoNnir from '../shared/assets/images/nnir-logo.png';
import { Auth } from 'features/auth';
import { logOut } from 'features/auth/slice';
import { Xray } from 'features/xray';
import { resetProcessResult } from 'features/xray/slice';
import { Button } from 'shared/ui/Button';
import { IconArrowLeft, IconLogout } from 'shared/ui/Icon';

const App = () => {
    const dispatch = useDispatch();
    const { token } = useSelector((state: RootState) => state.auth);
    const { resultProcess } = useSelector((state: RootState) => state.xray);

    const isAuthUser = token;

    return (
        <div className="App">
            <div className="container">
                <header className={classNames('App__header', { 'App__header-xray': isAuthUser })}>
                    {resultProcess && (
                        <Button type="link" handler={() => dispatch(resetProcessResult())}>
                            <IconArrowLeft />
                        </Button>
                    )}
                    <div className="App__wrap-logos">
                        <img src={logo} className="App__logo" alt="logo" />
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.67996 5L1.21814 8.46182L2.0836 9.32727L5.54542 5.86546L9.00723 9.32727L9.87269 8.46182L6.41087 5L9.87269 1.53819L9.00723 0.672731L5.54542 4.13455L2.0836 0.672729L1.21814 1.53818L4.67996 5Z"
                                fill="white"
                            ></path>
                        </svg>
                        <img src={logoNnir} className="App__logo App__logo-nnir" alt="logo" />
                    </div>

                    {isAuthUser && (
                        <Button type="link" handler={() => dispatch(logOut())}>
                            <IconLogout />
                        </Button>
                    )}
                </header>
            </div>
            {isAuthUser ? <Xray /> : <Auth />}
            <div className="container">
                <footer className="App__footer">© {new Date().getFullYear()}, AIRI</footer>
            </div>
        </div>
    );
};

export { App };
