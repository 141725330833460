import React, { FC } from 'react';
import { Button } from 'shared/ui/Button';
import { IconArchive, IconFile, IconImage, IconRotateCcw } from 'shared/ui/Icon';
import { onDownload } from 'shared/utils/downloadUtil';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { resetToInitTable } from '../../../slice';

const ToolsBar: FC = () => {
    const token = useSelector((state: RootState) => state.auth.token);
    const { resultProcess } = useSelector((state: RootState) => state.xray);
    const dispatch = useDispatch();

    if (!resultProcess) {
        return null;
    }

    return (
        <div className="d-flex justify-between">
            <Button
                type="ghost"
                size="small"
                handler={() => {
                    dispatch(resetToInitTable());
                }}
            >
                <div className="d-flex align-center gap-5">
                    <IconRotateCcw /> К исходным значениям
                </div>
            </Button>
            <div className="d-flex align-center gap-10">
                <div className="mr-10">Скачать:</div>
                <Button popover="Excel" type="ghost" size="small" handler={() => onDownload(resultProcess.excelLink, token, 'table.xlsx')}>
                    <IconFile />
                </Button>
                <Button href={resultProcess.fileLink} popover="Фото" type="ghost" size="small" download={true}>
                    <IconImage />
                </Button>
                <Button popover="Архив" type="ghost" size="small" handler={() => onDownload(resultProcess.archiveLink, token, 'archive.zip')}>
                    <IconArchive />
                </Button>
            </div>
        </div>
    );
};

export { ToolsBar };
