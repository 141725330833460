import { FC } from 'react';

const TableHead: FC = () => {
    return (
        <thead>
            <tr>
                <td align="center" rowSpan={2}>
                    Сустав
                </td>
                <td align="center" colSpan={2}>
                    Справа
                </td>
                <td align="center" colSpan={2}>
                    Слева
                </td>
            </tr>
            <tr>
                <td align="center">Эрозии</td>
                <td align="center">Сужение щелей</td>
                <td align="center">Эрозии</td>
                <td align="center">Сужение щелей</td>
            </tr>
        </thead>
    );
};

export { TableHead };