import { createSlice } from '@reduxjs/toolkit';
import { logOut } from 'features/auth/slice';
import { XrayState } from './data';
import { appApi } from 'shared/api';
import { cloneDeep } from 'lodash';
import set from 'lodash/set';

const initialState: XrayState = {
    resultProcess: null,
    table: null
};

const xraySlice = createSlice({
    name: 'xray',
    initialState,
    reducers: {
        setTable: (state, action) => {
            const updatedTable = cloneDeep(state.table);
            set(updatedTable, action.payload.name, action.payload.newValue);
            return {
                ...state,
                table: updatedTable
            }
        },
        resetToInitTable: (state) => {
            return {
                ...state,
                ...state,
                table: state.resultProcess?.table,
            }
        },
        resetProcessResult: (state) => {
            state.resultProcess = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logOut, (state) => {
            state.resultProcess = null;
        });
        builder.addMatcher(appApi.endpoints.uploadFile.matchFulfilled, (state, { payload }) => {
            state.resultProcess = payload
            state.table = payload.table
        });
    },
});

export const { resetProcessResult, setTable, resetToInitTable } = xraySlice.actions;
export default xraySlice.reducer;
