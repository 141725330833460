import classNames from 'classnames';
import '../index.css';

const IconArrowLeft = () => {
    return (
        <div className={classNames('UI-Icon')}>
            <svg
                className={classNames('UI-Icon__svg')}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M14.25 9H3.75M9 14.25 3.75 9 9 3.75"
                />
            </svg>
        </div>
    );
};

export { IconArrowLeft };
