import classNames from 'classnames';
import '../index.css';

const IconRotateCcw = () => {
    return (
        <div className={classNames('UI-Icon')}>
            <svg className={classNames('UI-Icon__svg')} xmlns='http://www.w3.org/2000/svg' width='18' height='18'
                 fill='none'>
                <path stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M2 4v4h4' />
                <path stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'
                      d='M3.757 11.66a6.475 6.475 0 0 0 2.451 3.25 6.156 6.156 0 0 0 3.854 1.08 6.195 6.195 0 0 0 3.707-1.528 6.537 6.537 0 0 0 2.074-3.52 6.69 6.69 0 0 0-.393-4.099 6.428 6.428 0 0 0-2.702-3.032 6.136 6.136 0 0 0-3.926-.748A6.23 6.23 0 0 0 5.248 4.9L2 8.049' />
            </svg>
        </div>
    );
};

export { IconRotateCcw };
