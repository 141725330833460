import classNames from 'classnames';
import '../index.css';

const IconFile = () => {
    return (
        <div className={classNames('UI-Icon')}>
            <svg
                className={classNames('UI-Icon__svg')}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
            >
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M10.5 2h-6A1.5 1.5 0 0 0 3 3.5v12A1.5 1.5 0 0 0 4.5 17h9a1.5 1.5 0 0 0 1.5-1.5v-9L10.5 2Z"
                />
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M10.5 2v4.5H15M12 10.25H6M12 13.25H6M7.5 7.25H6"
                />
            </svg>
        </div>
    );
};

export { IconFile };
